var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pb-10"},[_c('h1',{staticClass:"mb-4"},[_vm._v("Transaction History")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"9"}},[_c('v-select',{attrs:{"outlined":"","items":_vm.tabList,"item-text":"title","item-value":"index"},model:{value:(_vm.transactionTab),callback:function ($$v) {_vm.transactionTab=$$v},expression:"transactionTab"}}),_c('v-tabs',{staticClass:"custom-tab",attrs:{"color":"accent","vertical":_vm.$vuetify.breakpoint.smAndDown,"grow":"","center-active":!_vm.$vuetify.breakpoint.smAndDown,"show-arrows":""},model:{value:(_vm.transactionTab),callback:function ($$v) {_vm.transactionTab=$$v},expression:"transactionTab"}},[_c('v-tab',[_vm._v("Sales")]),(_vm.userCountry !== 'Ghana' || _vm.userCountry !== 'Kenya')?_c('v-tab',[_vm._v("Purchase ")]):_vm._e(),_c('v-tab',[_vm._v("Giftcard")]),_c('v-tab',[_vm._v("Cashback")]),_c('v-tab',[_vm._v("Fund Transfer")]),_c('v-tab',[_vm._v("Token Conversion History")]),_c('v-tab',[_vm._v("Bonus Conversion History")]),_c('v-tab',[_vm._v("Promo Codes Usage")]),_c('v-tab',[_vm._v("Bill History")]),(_vm.userCountry !== 'Ghana' || _vm.userCountry !== 'Kenya')?_c('v-tab',[_vm._v("Funding History ")]):_vm._e()],1),_c('v-tabs-items',{model:{value:(_vm.transactionTab),callback:function ($$v) {_vm.transactionTab=$$v},expression:"transactionTab"}},[_c('v-tab-item',[_c('v-card',{attrs:{"flat":"","loading":_vm.loading}},[_c('v-container',{staticClass:"py-10"},[(_vm.transactionSalesSorted.length === 0)?_c('v-col',{staticClass:"text-center"},[_c('no-transaction'),_c('v-btn',{staticClass:"accent darken-1 mx-auto",attrs:{"depressed":"","to":"/user/products"}},[_vm._v("Start Transaction ")])],1):_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.transactionSalesSorted,"show-expand":"","single-expand":true},scopedSlots:_vm._u([{key:"item.trx",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"truncate",attrs:{"title":item.trx}},[_vm._v(_vm._s(_vm.truncate(item.trx, 10)))])]}},{key:"item.rate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.rate.price))]),_c('span',{staticClass:"overline"},[_vm._v(" "+_vm._s(item.rate.currency))])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.amount.price))]),_c('span',{staticClass:"overline"},[_vm._v(" "+_vm._s(item.amount.currency))])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.price))]),_c('span',{staticClass:"overline"},[_vm._v(" "+_vm._s(_vm.currency))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-card',{staticClass:"text-capitalize text-center white--text pa-1",attrs:{"color":item.status == 'approved'
                        ? 'success'
                        : item.status == 'pending'
                        ? 'warning'
                        : 'error',"flat":"","rounded":"md","outlined":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"expanded-item",fn:function(ref){
                        var headers = ref.headers;
                        var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('div',[_c('v-row',[_c('v-col',[_c('div',{staticClass:"d-flex py-4"},[_c('h2',{staticClass:"mr-2"},[_vm._v(_vm._s(item.price))]),_c('span',{staticClass:"overline"},[_vm._v(" "+_vm._s(_vm.currency))])]),(item.status === 'declined')?_c('div',[_c('p',[_vm._v(" "+_vm._s(item.reason)+" ")]),(Array.isArray(item.declineImages))?_c('div',{staticClass:"d-flex"},[_c('CoolLightBox',{attrs:{"items":item.declineImages,"index":_vm.salesImageIndex},on:{"close":function($event){_vm.salesImageIndex = null}},scopedSlots:_vm._u([{key:"close",fn:function(){return [_c('svg',{staticClass:"w-6 h-6",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke-width":"1.5","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","d":"M6 18L18 6M6 6l12 12"}})])]},proxy:true}],null,true)}),_c('div',{staticStyle:{"display":"flex"}},_vm._l((item.declineImages),function(image,imageIndex){return _c('div',{key:imageIndex,staticStyle:{"width":"50px","height":"50px","margin-right":"10px","background-size":"cover"},style:({
                                    backgroundImage: 'url(' + image + ')'
                                  }),on:{"click":function($event){_vm.salesImageIndex = imageIndex}}})}),0)],1):_c('div',[_c('v-img',{attrs:{"src":item.declineImages,"width":"80","height":"80"}})],1)]):_vm._e()])],1)],1)])]}}],null,true)})],1)],1)],1),(_vm.COUNTRIES.GH !== _vm.userCountry || _vm.COUNTRIES.KE !== _vm.userCountry)?_c('v-tab-item',[_c('v-card',{attrs:{"flat":"","loading":_vm.loading}},[_c('v-container',{staticClass:"py-2"},[(_vm.transactionPurchase.length === 0)?_c('v-col',{staticClass:"text-center"},[_c('no-transaction'),_c('v-btn',{staticClass:"accent darken-1 mx-auto",attrs:{"depressed":"","to":"/user/products"}},[_vm._v("Start Transaction ")])],1):_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.transactionPurchaseSorted,"show-expand":"","single-expand":true},scopedSlots:_vm._u([{key:"item.trx",fn:function(ref){
                                  var item = ref.item;
return [_c('span',{staticClass:"truncate",attrs:{"title":item.trx}},[_vm._v(_vm._s(_vm.truncate(item.trx, 10)))])]}},{key:"item.rate",fn:function(ref){
                                  var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.rate.price))]),_c('span',{staticClass:"overline"},[_vm._v(" "+_vm._s(item.rate.currency))])]}},{key:"item.amount",fn:function(ref){
                                  var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.amount.price))]),_c('span',{staticClass:"overline"},[_vm._v(" "+_vm._s(item.amount.currency))])]}},{key:"item.price",fn:function(ref){
                                  var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.price))]),_c('span',{staticClass:"overline"},[_vm._v(" "+_vm._s(_vm.currency))])]}},{key:"item.status",fn:function(ref){
                                  var item = ref.item;
return [_c('v-card',{staticClass:"text-capitalize text-center white--text pa-1",attrs:{"color":item.status == 'approved'
                        ? 'success'
                        : item.status == 'pending'
                        ? 'warning'
                        : 'error',"flat":"","rounded":"md","outlined":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"expanded-item",fn:function(ref){
                        var headers = ref.headers;
                        var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('div',[_c('v-row',[_c('v-col',[_c('div',{staticClass:"d-flex py-4"},[_c('h2',{staticClass:"mr-2"},[_vm._v(_vm._s(item.price))]),_c('span',{staticClass:"overline"},[_vm._v(" "+_vm._s(_vm.currency))])]),(item.status === 'declined')?_c('div',[_c('p',[_vm._v(_vm._s(item.reason))]),(Array.isArray(item.declineImages))?_c('div',{staticClass:"d-flex"},[_c('CoolLightBox',{attrs:{"items":item.declineImages,"index":_vm.purchaseImageIndex},on:{"close":function($event){_vm.purchaseImageIndex = null}},scopedSlots:_vm._u([{key:"close",fn:function(){return [_c('svg',{staticClass:"w-6 h-6",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke-width":"1.5","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","d":"M6 18L18 6M6 6l12 12"}})])]},proxy:true}],null,true)}),_c('div',{staticStyle:{"display":"flex"}},_vm._l((item.declineImages),function(image,imageIndex){return _c('div',{key:imageIndex,staticStyle:{"width":"50px","height":"50px","margin-right":"10px","background-size":"cover"},style:({
                                    backgroundImage: 'url(' + image + ')'
                                  }),on:{"click":function($event){_vm.purchaseImageIndex = imageIndex}}})}),0)],1):_c('div',[_c('v-img',{attrs:{"src":item.declineImages,"width":"80","height":"80"}})],1)]):_vm._e()])],1)],1)])]}}],null,true)})],1)],1)],1):_vm._e(),_c('v-tab-item',[_c('v-card',{attrs:{"flat":"","loading":_vm.loading}},[_c('v-container',{staticClass:"py-2"},[(_vm.transactionGiftcard.length === 0)?_c('v-col',{staticClass:"text-center"},[_c('no-transaction'),_c('v-btn',{staticClass:"accent darken-1 mx-auto",attrs:{"depressed":"","to":"/user/products"}},[_vm._v("Start Transaction ")])],1):_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.giftcardHeaders,"items":_vm.transactionGiftcardSorted,"show-expand":"","single-expand":true},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
                                  var item = ref.item;
return [_c('v-img',{attrs:{"src":item.image,"width":"45"}})]}},{key:"item.amount",fn:function(ref){
                                  var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.amount))]),_c('span',{staticClass:"overline"},[_vm._v(" "+_vm._s(_vm.currency))])]}},{key:"item.status",fn:function(ref){
                                  var item = ref.item;
return [_c('v-card',{staticClass:"text-capitalize text-center white--text pa-1",attrs:{"color":item.status == 'approved'
                        ? 'success'
                        : item.status == 'pending'
                        ? 'warning'
                        : 'error',"flat":"","rounded":"md","outlined":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"expanded-item",fn:function(ref){
                        var headers = ref.headers;
                        var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('div',[_c('v-row',[_c('v-col',[_c('div',{staticClass:"d-flex py-4"},[_c('h2',{staticClass:"mr-2"},[_vm._v(_vm._s(item.amount))]),_c('span',{staticClass:"overline"},[_vm._v(" "+_vm._s(_vm.currency))])]),(item.status === 'declined')?_c('div',[_c('p',[_vm._v(_vm._s(item.reason))]),(Array.isArray(item.declineImages))?_c('div',{staticClass:"d-flex"},[_c('CoolLightBox',{attrs:{"items":item.declineImages,"index":_vm.giftcardImageIndex},on:{"close":function($event){_vm.giftcardImageIndex = null}},scopedSlots:_vm._u([{key:"close",fn:function(){return [_c('svg',{staticClass:"w-6 h-6",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke-width":"1.5","stroke":"currentColor"}},[_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","d":"M6 18L18 6M6 6l12 12"}})])]},proxy:true}],null,true)}),_c('div',{staticStyle:{"display":"flex"}},_vm._l((item.declineImages),function(image,imageIndex){return _c('div',{key:imageIndex,staticStyle:{"width":"50px","height":"50px","margin-right":"10px","background-size":"cover"},style:({
                                    backgroundImage: 'url(' + image + ')'
                                  }),on:{"click":function($event){_vm.giftcardImageIndex = imageIndex}}})}),0)],1):_c('div',[_c('v-img',{attrs:{"src":item.declineImages,"width":"80","height":"80"}})],1)]):_vm._e()])],1)],1)])]}}],null,true)})],1)],1)],1),_c('v-tab-item',[_c('v-card',{attrs:{"flat":"","loading":_vm.loading}},[_c('v-container',{staticClass:"py-2"},[(_vm.cashbackHistory.length === 0)?_c('v-col',{staticClass:"text-center"},[_c('no-transaction'),_c('v-btn',{staticClass:"accent darken-1 mx-auto",attrs:{"depressed":"","to":"/user/products"}},[_vm._v("Start Transaction ")])],1):_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headerCashback,"items":_vm.cashbackHistory},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
                                  var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(new Date(item.createdAt).toDateString())+" ")])]}},{key:"item.amount",fn:function(ref){
                                  var item = ref.item;
return [_c('span',[_vm._v(_vm._s(Number(item.amount).toFixed(2)))])]}},{key:"item.source",fn:function(ref){
                                  var item = ref.item;
return [_c('span',{staticClass:"capitalize"},[_vm._v(_vm._s(item.source)+" "),(item.category)?_c('span',[_vm._v("- "+_vm._s(item.category))]):_vm._e()])]}},{key:"item.type",fn:function(ref){
                                  var item = ref.item;
return [_c('v-card',{staticClass:"text-capitalize text-center white--text pa-1",attrs:{"color":item.type == 'credit'
                        ? 'success'
                        : item.status == 'debit'
                        ? 'warning'
                        : 'error',"flat":"","rounded":"md","outlined":""}},[_vm._v(" "+_vm._s(item.type)+" ")])]}}],null,true)})],1)],1)],1),_c('v-tab-item',[_c('v-card',[_c('v-container',[_c('v-data-table',{attrs:{"headers":_vm.headerFundTransfer,"items":_vm.fundTransfers},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
                        var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.createdAt).toDateString())+" ")]}},{key:"item.amount",fn:function(ref){
                        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.amount))]),_c('span',{staticClass:"overline"},[_vm._v(" "+_vm._s(_vm.currency))])]}},{key:"item.status",fn:function(ref){
                        var item = ref.item;
return [_c('v-card',{staticClass:"text-capitalize text-center white--text pa-1",attrs:{"color":item.status == 'approved'
                        ? 'success'
                        : item.status == 'pending'
                        ? 'warning'
                        : 'error',"flat":"","rounded":"md","outlined":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"py-3 text-center"},[_c('v-img',{staticClass:"mx-auto",attrs:{"src":require("@/assets/img/undraw_empty.svg"),"width":"325","alt":"empty"}}),_c('h2',{staticClass:"mt-3"},[_vm._v("No previous transfer transactions")])],1)]},proxy:true}],null,true)})],1)],1)],1),_c('v-tab-item',[_c('v-card',[_c('v-container',[_c('v-data-table',{attrs:{"headers":_vm.headerTokenHistory,"items":_vm.tokenHistory},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
                        var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.createdAt).toDateString())+" ")]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"py-3 text-center"},[_c('v-img',{staticClass:"mx-auto",attrs:{"src":require("@/assets/img/undraw_empty.svg"),"width":"325","alt":"empty"}}),_c('h2',{staticClass:"mt-3"},[_vm._v("No previous token conversion")])],1)]},proxy:true}],null,true)})],1)],1)],1),_c('v-tab-item',[_c('v-card',[_c('v-container',[_c('v-data-table',{attrs:{"headers":_vm.headerTokenHistory,"items":_vm.bonusHistory},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
                        var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.createdAt).toDateString())+" ")]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"py-3 text-center"},[_c('v-img',{staticClass:"mx-auto",attrs:{"src":require("@/assets/img/undraw_empty.svg"),"width":"325","alt":"empty"}}),_c('h2',{staticClass:"mt-3"},[_vm._v("No previous bonus conversion")])],1)]},proxy:true}],null,true)})],1)],1)],1),_c('v-tab-item',[_c('v-card',[_c('v-container',[_c('v-data-table',{attrs:{"headers":_vm.headerPromoCodes,"items":_vm.promoCodeHistory},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
                        var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.createdAt).toDateString())+" ")]}},{key:"item.trx",fn:function(ref){
                        var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.product.name)+" ")])]}},{key:"item.trxAmount",fn:function(ref){
                        var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.trxAmount)+" ")])]}},{key:"item.trxStatus",fn:function(ref){
                        var item = ref.item;
return [_c('v-card',{staticClass:"text-capitalize text-center white--text pa-1",attrs:{"color":item.transaction.status == 'approved'
                        ? 'success'
                        : item.transaction.status == 'pending'
                        ? 'warning'
                        : 'error',"flat":"","rounded":"md","outlined":""}},[_vm._v(" "+_vm._s(item.transaction.status)+" ")])]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"py-3 text-center"},[_c('v-img',{staticClass:"mx-auto",attrs:{"src":require("@/assets/img/undraw_empty.svg"),"width":"325","alt":"empty"}}),_c('h2',{staticClass:"mt-3"},[_vm._v("No previous bonus conversion")])],1)]},proxy:true}],null,true)})],1)],1)],1),_c('v-tab-item',[_c('v-card',[_c('v-container',[_c('v-data-table',{attrs:{"headers":_vm.headerBill,"items":_vm.historyTransaction},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
                        var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.createdAt).toDateString())+" ")]}},{key:"item.status",fn:function(ref){
                        var item = ref.item;
return [_c('v-card',{staticClass:"text-capitalize text-center white--text pa-1",attrs:{"color":item.status == 'completed'
                        ? 'success'
                        : item.status == 'pending'
                        ? 'warning'
                        : 'error',"flat":"","rounded":"md","outlined":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.amount",fn:function(ref){
                        var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.currency)+" "+_vm._s(item.amountCharged))])]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"py-3 text-center"},[_c('v-img',{staticClass:"mx-auto",attrs:{"src":require("@/assets/img/undraw_empty.svg"),"width":"325","alt":"empty"}}),_c('h2',{staticClass:"mt-3"},[_vm._v("No previous bonus conversion")])],1)]},proxy:true}],null,true)})],1)],1)],1),(_vm.COUNTRIES.GH !== _vm.userCountry || _vm.COUNTRIES.KE !== _vm.userCountry)?_c('v-tab-item',[_c('v-card',{attrs:{"flat":"","loading":_vm.loading}},[_c('v-container',{staticClass:"py-2"},[(_vm.fundingRequest.length === 0)?_c('v-col',{staticClass:"text-center"},[_c('no-transaction')],1):_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headerFunding,"items":_vm.fundingRequest},scopedSlots:_vm._u([{key:"item.amount",fn:function(ref){
                        var item = ref.item;
return [_vm._v(" NGN "+_vm._s(item.amount)+" ")]}},{key:"item.date",fn:function(ref){
                        var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.createdAt).toDateString())+" ")]}},{key:"item.status",fn:function(ref){
                        var item = ref.item;
return [_c('v-card',{staticClass:"text-capitalize text-center white--text pa-1",attrs:{"color":item.status == 'approved'
                        ? 'success'
                        : item.status == 'pending'
                        ? 'warning'
                        : 'error',"flat":"","rounded":"md","outlined":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}}],null,true)})],1)],1)],1):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-sheet',{staticClass:"pa-6",attrs:{"color":"white","rounded":"lg"}},[_c('h4',{staticClass:"text-h6 mb-4"},[_vm._v("Filter")]),_c('v-divider'),_c('v-radio-group',{model:{value:(_vm.filterBy),callback:function ($$v) {_vm.filterBy=$$v},expression:"filterBy"}},_vm._l((_vm.filterOptions),function(filterOption){return _c('v-sheet',{key:filterOption.value,staticClass:"py-3 px-4 text-left my-2",attrs:{"outlined":"","rounded":"lg","elevation":"0"}},[_c('v-radio',{attrs:{"color":"accent","label":filterOption.title,"value":filterOption.value}})],1)}),1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }