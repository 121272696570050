<template>
  <v-container class="pb-10">
    <h1 class="mb-4">Transaction History</h1>
    <!-- <p class="text-subtitle-1 font-weight-bold">
      Got questions? Call or Whatsapp {{ siteData.phone }}
    </p> -->
    <v-row>
      <v-col class="" cols="12" md="9">
        <v-select
          outlined
          :items="tabList"
          item-text="title"
          item-value="index"
          v-model="transactionTab"
        ></v-select>
        <v-tabs
          color="accent"
          v-model="transactionTab"
          :vertical="$vuetify.breakpoint.smAndDown"
          grow
          :center-active="!$vuetify.breakpoint.smAndDown"
          show-arrows
          class="custom-tab"
        >
          <v-tab>Sales</v-tab>
          <v-tab v-if="userCountry !== 'Ghana' || userCountry !== 'Kenya'"
            >Purchase
          </v-tab>
          <v-tab>Giftcard</v-tab>
          <v-tab>Cashback</v-tab>
          <v-tab>Fund Transfer</v-tab>
          <v-tab>Token Conversion History</v-tab>
          <v-tab>Bonus Conversion History</v-tab>
          <v-tab>Promo Codes Usage</v-tab>
          <v-tab>Bill History</v-tab>
          <v-tab v-if="userCountry !== 'Ghana' || userCountry !== 'Kenya'"
            >Funding History
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="transactionTab">
          <!--          Sales-->
          <v-tab-item>
            <v-card flat :loading="loading">
              <v-container class="py-10">
                <v-col
                  v-if="transactionSalesSorted.length === 0"
                  class="text-center"
                >
                  <no-transaction />
                  <v-btn
                    depressed
                    class="accent darken-1 mx-auto"
                    to="/user/products"
                    >Start Transaction
                  </v-btn>
                </v-col>
                <v-data-table
                  v-else
                  :headers="headers"
                  :items="transactionSalesSorted"
                  class="elevation-0"
                  show-expand
                  :single-expand="true"
                >
                  <template v-slot:[`item.trx`]="{ item }">
                    <span :title="item.trx" class="truncate">{{
                      truncate(item.trx, 10)
                    }}</span>
                  </template>
                  <template v-slot:[`item.rate`]="{ item }">
                    <span>{{ item.rate.price }}</span>
                    <span class="overline"> {{ item.rate.currency }}</span>
                  </template>
                  <template v-slot:[`item.amount`]="{ item }">
                    <span>{{ item.amount.price }}</span>
                    <span class="overline"> {{ item.amount.currency }}</span>
                  </template>
                  <template v-slot:[`item.price`]="{ item }">
                    <span>{{ item.price }}</span>
                    <span class="overline"> {{ currency }}</span>
                  </template>
                  <template v-slot:[`item.status`]="{ item }">
                    <v-card
                      :color="
                        item.status == 'approved'
                          ? 'success'
                          : item.status == 'pending'
                          ? 'warning'
                          : 'error'
                      "
                      flat
                      rounded="md"
                      outlined
                      class="text-capitalize text-center white--text pa-1"
                    >
                      {{ item.status }}
                    </v-card>
                  </template>
                  <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                      <div>
                        <v-row>
                          <v-col>
                            <div class="d-flex py-4">
                              <h2 class="mr-2">{{ item.price }}</h2>
                              <span class="overline"> {{ currency }}</span>
                            </div>
                            <div v-if="item.status === 'declined'">
                              <p>
                                {{ item.reason }}
                              </p>
                              <div
                                v-if="Array.isArray(item.declineImages)"
                                class="d-flex"
                              >
                                <CoolLightBox
                                  :items="item.declineImages"
                                  :index="salesImageIndex"
                                  @close="salesImageIndex = null"
                                >
                                  <template v-slot:close>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke-width="1.5"
                                      stroke="currentColor"
                                      class="w-6 h-6"
                                    >
                                      <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M6 18L18 6M6 6l12 12"
                                      />
                                    </svg>
                                  </template>
                                </CoolLightBox>
                                <div style="display: flex">
                                  <div
                                    style="
                                      width: 50px;
                                      height: 50px;
                                      margin-right: 10px;
                                      background-size: cover;
                                    "
                                    v-for="(
                                      image, imageIndex
                                    ) in item.declineImages"
                                    :key="imageIndex"
                                    @click="salesImageIndex = imageIndex"
                                    :style="{
                                      backgroundImage: 'url(' + image + ')'
                                    }"
                                  ></div>
                                </div>
                              </div>
                              <div v-else>
                                <v-img
                                  :src="item.declineImages"
                                  width="80"
                                  height="80"
                                ></v-img>
                              </div>
                            </div>
                          </v-col>
                        </v-row>
                      </div>
                    </td>
                  </template>
                </v-data-table>
              </v-container>
            </v-card>
          </v-tab-item>
          <!-- Purchase -->
          <v-tab-item
            v-if="COUNTRIES.GH !== userCountry || COUNTRIES.KE !== userCountry"
          >
            <v-card flat :loading="loading">
              <v-container class="py-2">
                <v-col
                  v-if="transactionPurchase.length === 0"
                  class="text-center"
                >
                  <no-transaction />
                  <v-btn
                    depressed
                    class="accent darken-1 mx-auto"
                    to="/user/products"
                    >Start Transaction
                  </v-btn>
                </v-col>
                <v-data-table
                  v-else
                  :headers="headers"
                  :items="transactionPurchaseSorted"
                  class="elevation-0"
                  show-expand
                  :single-expand="true"
                >
                  <template v-slot:[`item.trx`]="{ item }">
                    <span :title="item.trx" class="truncate">{{
                      truncate(item.trx, 10)
                    }}</span>
                  </template>
                  <template v-slot:[`item.rate`]="{ item }">
                    <span>{{ item.rate.price }}</span>
                    <span class="overline"> {{ item.rate.currency }}</span>
                  </template>
                  <template v-slot:[`item.amount`]="{ item }">
                    <span>{{ item.amount.price }}</span>
                    <span class="overline"> {{ item.amount.currency }}</span>
                  </template>
                  <template v-slot:[`item.price`]="{ item }">
                    <span>{{ item.price }}</span>
                    <span class="overline"> {{ currency }}</span>
                  </template>
                  <template v-slot:[`item.status`]="{ item }">
                    <v-card
                      :color="
                        item.status == 'approved'
                          ? 'success'
                          : item.status == 'pending'
                          ? 'warning'
                          : 'error'
                      "
                      flat
                      rounded="md"
                      outlined
                      class="text-capitalize text-center white--text pa-1"
                    >
                      {{ item.status }}
                    </v-card>
                  </template>
                  <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                      <div>
                        <v-row>
                          <v-col>
                            <div class="d-flex py-4">
                              <h2 class="mr-2">{{ item.price }}</h2>
                              <span class="overline"> {{ currency }}</span>
                            </div>
                            <div v-if="item.status === 'declined'">
                              <p>{{ item.reason }}</p>
                              <div
                                v-if="Array.isArray(item.declineImages)"
                                class="d-flex"
                              >
                                <CoolLightBox
                                  :items="item.declineImages"
                                  :index="purchaseImageIndex"
                                  @close="purchaseImageIndex = null"
                                >
                                  <template v-slot:close>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke-width="1.5"
                                      stroke="currentColor"
                                      class="w-6 h-6"
                                    >
                                      <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M6 18L18 6M6 6l12 12"
                                      />
                                    </svg>
                                  </template>
                                </CoolLightBox>
                                <div style="display: flex">
                                  <div
                                    style="
                                      width: 50px;
                                      height: 50px;
                                      margin-right: 10px;
                                      background-size: cover;
                                    "
                                    v-for="(
                                      image, imageIndex
                                    ) in item.declineImages"
                                    :key="imageIndex"
                                    @click="purchaseImageIndex = imageIndex"
                                    :style="{
                                      backgroundImage: 'url(' + image + ')'
                                    }"
                                  ></div>
                                </div>
                              </div>
                              <div v-else>
                                <v-img
                                  :src="item.declineImages"
                                  width="80"
                                  height="80"
                                ></v-img>
                              </div>
                            </div>
                          </v-col>
                        </v-row>
                      </div>
                    </td>
                  </template>
                </v-data-table>
              </v-container>
            </v-card>
          </v-tab-item>

          <!--          Gittcard-->
          <v-tab-item>
            <v-card flat :loading="loading">
              <v-container class="py-2">
                <v-col
                  v-if="transactionGiftcard.length === 0"
                  class="text-center"
                >
                  <no-transaction />
                  <v-btn
                    depressed
                    class="accent darken-1 mx-auto"
                    to="/user/products"
                    >Start Transaction
                  </v-btn>
                </v-col>
                <v-data-table
                  v-else
                  :headers="giftcardHeaders"
                  :items="transactionGiftcardSorted"
                  class="elevation-0"
                  show-expand
                  :single-expand="true"
                >
                  <template v-slot:[`item.image`]="{ item }">
                    <v-img :src="item.image" width="45"></v-img>
                  </template>
                  <template v-slot:[`item.amount`]="{ item }">
                    <span>{{ item.amount }}</span>
                    <span class="overline"> {{ currency }}</span>
                  </template>
                  <template v-slot:[`item.status`]="{ item }">
                    <v-card
                      :color="
                        item.status == 'approved'
                          ? 'success'
                          : item.status == 'pending'
                          ? 'warning'
                          : 'error'
                      "
                      flat
                      rounded="md"
                      outlined
                      class="text-capitalize text-center white--text pa-1"
                    >
                      {{ item.status }}
                    </v-card>
                  </template>
                  <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                      <div>
                        <v-row>
                          <v-col>
                            <div class="d-flex py-4">
                              <h2 class="mr-2">{{ item.amount }}</h2>
                              <span class="overline"> {{ currency }}</span>
                            </div>
                            <div v-if="item.status === 'declined'">
                              <p>{{ item.reason }}</p>
                              <div
                                v-if="Array.isArray(item.declineImages)"
                                class="d-flex"
                              >
                                <CoolLightBox
                                  :items="item.declineImages"
                                  :index="giftcardImageIndex"
                                  @close="giftcardImageIndex = null"
                                >
                                  <template v-slot:close>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke-width="1.5"
                                      stroke="currentColor"
                                      class="w-6 h-6"
                                    >
                                      <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M6 18L18 6M6 6l12 12"
                                      />
                                    </svg>
                                  </template>
                                </CoolLightBox>
                                <div style="display: flex">
                                  <div
                                    style="
                                      width: 50px;
                                      height: 50px;
                                      margin-right: 10px;
                                      background-size: cover;
                                    "
                                    v-for="(
                                      image, imageIndex
                                    ) in item.declineImages"
                                    :key="imageIndex"
                                    @click="giftcardImageIndex = imageIndex"
                                    :style="{
                                      backgroundImage: 'url(' + image + ')'
                                    }"
                                  ></div>
                                </div>
                              </div>
                              <div v-else>
                                <v-img
                                  :src="item.declineImages"
                                  width="80"
                                  height="80"
                                ></v-img>
                              </div>
                            </div>
                          </v-col>
                        </v-row>
                      </div>
                    </td>
                  </template>
                </v-data-table>
              </v-container>
            </v-card>
          </v-tab-item>

          <!--          Cashback-->
          <v-tab-item>
            <v-card flat :loading="loading">
              <v-container class="py-2">
                <v-col v-if="cashbackHistory.length === 0" class="text-center">
                  <no-transaction />
                  <v-btn
                    depressed
                    class="accent darken-1 mx-auto"
                    to="/user/products"
                    >Start Transaction
                  </v-btn>
                </v-col>
                <v-data-table
                  v-else
                  :headers="headerCashback"
                  :items="cashbackHistory"
                  class="elevation-0"
                >
                  <template v-slot:[`item.date`]="{ item }">
                    <span>
                      {{ new Date(item.createdAt).toDateString() }}
                    </span>
                  </template>
                  <template v-slot:[`item.amount`]="{ item }">
                    <span>{{ Number(item.amount).toFixed(2) }}</span>
                  </template>
                  <template v-slot:[`item.source`]="{ item }">
                    <span class="capitalize"
                      >{{ item.source }}
                      <span v-if="item.category">- {{ item.category }}</span>
                    </span>
                  </template>
                  <template v-slot:[`item.type`]="{ item }">
                    <v-card
                      :color="
                        item.type == 'credit'
                          ? 'success'
                          : item.status == 'debit'
                          ? 'warning'
                          : 'error'
                      "
                      flat
                      rounded="md"
                      outlined
                      class="text-capitalize text-center white--text pa-1"
                    >
                      {{ item.type }}
                    </v-card>
                  </template>
                </v-data-table>
              </v-container>
            </v-card>
          </v-tab-item>

          <v-tab-item>
            <v-card>
              <v-container>
                <v-data-table
                  :headers="headerFundTransfer"
                  :items="fundTransfers"
                >
                  <template v-slot:[`item.date`]="{ item }">
                    {{ new Date(item.createdAt).toDateString() }}
                  </template>

                  <template v-slot:[`item.amount`]="{ item }">
                    <span>{{ item.amount }}</span>
                    <span class="overline"> {{ currency }}</span>
                  </template>
                  <template v-slot:[`item.status`]="{ item }">
                    <v-card
                      :color="
                        item.status == 'approved'
                          ? 'success'
                          : item.status == 'pending'
                          ? 'warning'
                          : 'error'
                      "
                      flat
                      rounded="md"
                      outlined
                      class="text-capitalize text-center white--text pa-1"
                    >
                      {{ item.status }}
                    </v-card>
                  </template>

                  <template v-slot:no-data>
                    <div class="py-3 text-center">
                      <v-img
                        src="@/assets/img/undraw_empty.svg"
                        width="325"
                        class="mx-auto"
                        alt="empty"
                      />
                      <h2 class="mt-3">No previous transfer transactions</h2>
                    </div>
                  </template>
                </v-data-table>
              </v-container>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card>
              <v-container>
                <v-data-table
                  :headers="headerTokenHistory"
                  :items="tokenHistory"
                >
                  <template v-slot:[`item.date`]="{ item }">
                    {{ new Date(item.createdAt).toDateString() }}
                  </template>

                  <template v-slot:no-data>
                    <div class="py-3 text-center">
                      <v-img
                        src="@/assets/img/undraw_empty.svg"
                        width="325"
                        class="mx-auto"
                        alt="empty"
                      />
                      <h2 class="mt-3">No previous token conversion</h2>
                    </div>
                  </template>
                </v-data-table>
              </v-container>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card>
              <v-container>
                <v-data-table
                  :headers="headerTokenHistory"
                  :items="bonusHistory"
                >
                  <template v-slot:[`item.date`]="{ item }">
                    {{ new Date(item.createdAt).toDateString() }}
                  </template>

                  <template v-slot:no-data>
                    <div class="py-3 text-center">
                      <v-img
                        src="@/assets/img/undraw_empty.svg"
                        width="325"
                        class="mx-auto"
                        alt="empty"
                      />
                      <h2 class="mt-3">No previous bonus conversion</h2>
                    </div>
                  </template>
                </v-data-table>
              </v-container>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card>
              <v-container>
                <v-data-table
                  :headers="headerPromoCodes"
                  :items="promoCodeHistory"
                >
                  <template v-slot:[`item.date`]="{ item }">
                    {{ new Date(item.createdAt).toDateString() }}
                  </template>

                  <!-- <template v-slot:[`item.value`]="{ item }">
                    <p>
                      {{ item.discountValue }}
                      {{ item.discountType === 'percentage' ? '%' : '(FIXED)' }}
                    </p>
                  </template> -->

                  <template v-slot:[`item.trx`]="{ item }">
                    <span>
                      {{ item.product.name }}
                    </span>
                  </template>

                  <template v-slot:[`item.trxAmount`]="{ item }">
                    <span>
                      {{ item.trxAmount }}
                    </span>
                  </template>

                  <template v-slot:[`item.trxStatus`]="{ item }">
                    <v-card
                      :color="
                        item.transaction.status == 'approved'
                          ? 'success'
                          : item.transaction.status == 'pending'
                          ? 'warning'
                          : 'error'
                      "
                      flat
                      rounded="md"
                      outlined
                      class="text-capitalize text-center white--text pa-1"
                    >
                      {{ item.transaction.status }}
                    </v-card>
                  </template>

                  <template v-slot:no-data>
                    <div class="py-3 text-center">
                      <v-img
                        src="@/assets/img/undraw_empty.svg"
                        width="325"
                        class="mx-auto"
                        alt="empty"
                      />
                      <h2 class="mt-3">No previous bonus conversion</h2>
                    </div>
                  </template>
                </v-data-table>
              </v-container>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card>
              <v-container>
                <v-data-table :headers="headerBill" :items="historyTransaction">
                  <template v-slot:[`item.date`]="{ item }">
                    {{ new Date(item.createdAt).toDateString() }}
                  </template>

                  <template v-slot:[`item.status`]="{ item }">
                    <v-card
                      :color="
                        item.status == 'completed'
                          ? 'success'
                          : item.status == 'pending'
                          ? 'warning'
                          : 'error'
                      "
                      flat
                      rounded="md"
                      outlined
                      class="text-capitalize text-center white--text pa-1"
                    >
                      {{ item.status }}
                    </v-card>
                  </template>

                  <template v-slot:[`item.amount`]="{ item }">
                    <span> {{ item.currency }} {{ item.amountCharged }}</span>
                  </template>

                  <template v-slot:no-data>
                    <div class="py-3 text-center">
                      <v-img
                        src="@/assets/img/undraw_empty.svg"
                        width="325"
                        class="mx-auto"
                        alt="empty"
                      />
                      <h2 class="mt-3">No previous bonus conversion</h2>
                    </div>
                  </template>
                </v-data-table>
              </v-container>
            </v-card>
          </v-tab-item>
          <!-- Funding History -->
          <v-tab-item
            v-if="COUNTRIES.GH !== userCountry || COUNTRIES.KE !== userCountry"
          >
            <v-card flat :loading="loading">
              <v-container class="py-2">
                <v-col v-if="fundingRequest.length === 0" class="text-center">
                  <no-transaction />
                </v-col>
                <v-data-table
                  v-else
                  :headers="headerFunding"
                  :items="fundingRequest"
                  class="elevation-0"
                >
                  <template v-slot:[`item.amount`]="{ item }">
                    NGN {{ item.amount }}
                  </template>
                  <template v-slot:[`item.date`]="{ item }">
                    {{ new Date(item.createdAt).toDateString() }}
                  </template>
                  <template v-slot:[`item.status`]="{ item }">
                    <v-card
                      :color="
                        item.status == 'approved'
                          ? 'success'
                          : item.status == 'pending'
                          ? 'warning'
                          : 'error'
                      "
                      flat
                      rounded="md"
                      outlined
                      class="text-capitalize text-center white--text pa-1"
                    >
                      {{ item.status }}
                    </v-card>
                  </template>
                </v-data-table>
              </v-container>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
      <v-col cols="12" md="3">
        <v-sheet color="white" class="pa-6" rounded="lg">
          <h4 class="text-h6 mb-4">Filter</h4>
          <v-divider></v-divider>
          <v-radio-group v-model="filterBy">
            <v-sheet
              outlined
              rounded="lg"
              elevation="0"
              v-for="filterOption in filterOptions"
              :key="filterOption.value"
              class="py-3 px-4 text-left my-2"
            >
              <v-radio
                color="accent"
                :label="filterOption.title"
                :value="filterOption.value"
              ></v-radio>
            </v-sheet>
          </v-radio-group>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import noTransaction from '../../components/noTransaction.vue'
import transactionApi from '../../api/users/transactions'
import { numberWithCommas, truncate } from '../../utils/helpers'
import { mapState } from 'vuex'
import walletApi from '../../api/users/wallet'
import promoCodes from '../../api/promo-codes'

import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import bill from '../../api/users/bill'
import cashbackApi from '../../api/users/cashback'
import { COUNTRIES, getCurrencySymbol } from '../../utils/countryHelpers'

export default {
  name: 'TransactionHistory',
  components: { noTransaction, CoolLightBox },
  data: () => ({
    truncate,
    COUNTRIES,
    transactionTab: null,
    loading: false,
    filterBy: 'all',
    filterOptions: [
      { title: 'All', value: 'all' },
      { title: 'Pending', value: 'pending' },
      { title: 'Approved', value: 'approved' },
      { title: 'Declined', value: 'declined' }
    ],
    giftcardHeaders: [
      {
        text: 'Trx ID',
        value: 'trx',
        sortable: false
      },
      {
        text: 'Date',
        value: 'date'
      },
      {
        text: 'Giftcard',
        value: 'image'
      },
      {
        text: 'Name',
        value: 'giftcard.name'
      },
      {
        text: 'Amount',
        value: 'amount'
      },
      {
        text: 'Status',
        value: 'status'
      },
      { text: '', value: 'data-table-expand' }
    ],
    headersAirtime: [
      {
        text: 'Date',
        value: 'date',
        align: 'start'
      },
      {
        text: 'Provider/Network',
        value: 'network'
      },
      {
        text: 'Status',
        value: 'status'
      }
    ],
    headers: [
      {
        text: 'Trx ID.',
        align: 'start',
        sortable: false,
        value: 'trx'
      },
      {
        text: 'Date',
        value: 'date'
      },
      {
        text: 'Currency',
        value: 'currency'
      },
      {
        text: 'Amount',
        value: 'amount'
      },

      {
        text: 'Rate',
        value: 'rate'
      },
      {
        text: 'Price',
        value: 'price'
      },
      {
        text: 'Status',
        value: 'status'
      },
      { text: '', value: 'data-table-expand' }
    ],
    headerFundTransfer: [
      {
        text: 'Trx ID.',
        align: 'start',
        sortable: false,
        value: 'transaction_id'
      },
      {
        text: 'Date',
        value: 'date'
      },
      {
        text: 'Transfer To',
        value: 'send_details'
      },
      {
        text: 'Amount',
        value: 'amount'
      },
      {
        text: 'Status',
        value: 'status'
      }
    ],
    headerPromoCodes: [
      {
        text: 'Date',
        value: 'date'
      },
      {
        text: 'Code Used',
        value: 'promoCode.code'
      },
      {
        text: 'Tnx Done',
        value: 'trx'
      },
      {
        text: 'Tnx Amount',
        value: 'trxAmount'
      },
      {
        text: 'Promo Code Amount',
        value: 'promoCodeAmount'
      },
      {
        text: 'Tnx Status',
        value: 'trxStatus'
      }
    ],
    headerTokenHistory: [
      {
        text: 'Token',
        align: 'start',
        sortable: false,
        value: 'amount'
      },
      {
        text: 'Date',
        value: 'date'
      },
      {
        text: 'Current Rate',
        value: 'currentRate'
      },
      {
        text: 'Description',
        value: 'description'
      }
    ],
    headerBill: [
      {
        text: 'Type',
        align: 'start',
        sortable: false,
        value: 'type'
      },
      {
        text: 'Date',
        value: 'date'
      },
      {
        text: 'Amount Charged',
        value: 'amount'
      },
      {
        text: 'Package',
        value: 'package'
      },
      {
        text: 'Provider',
        value: 'provider'
      },
      {
        text: 'Status',
        value: 'status'
      }
    ],
    headerFunding: [
      {
        text: 'Date',
        value: 'date'
      },
      {
        text: 'Amount',
        value: 'amount'
      },
      {
        text: 'Status',
        value: 'status'
      },
      {
        text: 'Reference No.',
        value: 'reference'
      }
    ],
    headerCashback: [
      {
        text: 'Date',
        value: 'date'
      },
      {
        text: 'Points',
        value: 'amount'
      },
      {
        text: 'Type',
        value: 'type'
      },
      {
        text: 'Description',
        value: 'source'
      }
    ],
    transactionSales: [],
    transactionPurchase: [],
    transactionGiftcard: [],
    recharges: [],
    fundTransfers: [],
    tokenHistory: [],
    bonusHistory: [],
    promoCodeHistory: [],
    giftcardImageIndex: null,
    purchaseImageIndex: null,
    salesImageIndex: null,
    historyTransaction: [],
    fundingRequest: [],
    cashbackHistory: []
  }),
  created() {
    this.getAllTransaction()
    this.getFundTransfer()
    this.getPromoCodeUsage()
    this.getTokenHistory()
    this.getBonusHistory()
    this.getHistoryTransaction()
    this.getFundingHistory()
    this.getCashbackHistory()
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      siteData: (state) => state.user.siteSettings
    }),
    currency() {
      return getCurrencySymbol(this.userCountry.toLocaleLowerCase())
    },
    tabList() {
      const list = [
        {
          title: 'Sales',
          index: 0
        },
        {
          title: 'Purchase',
          index: 1
        },
        {
          title: 'Giftcard',
          index: 2
        },
        {
          title: 'Cashback',
          index: 3
        },
        {
          title: 'Fund Transfer',
          index: 4
        },
        {
          title: 'Token Conversion',
          index: 5
        },
        {
          title: 'Bonus Conversion',
          index: 6
        },
        {
          title: 'Promo Code Usage',
          index: 7
        },
        {
          title: 'Bill History',
          index: 8
        },
        {
          title: 'Funding History',
          index: 9
        }
      ]

      if (
        this.userCountry === COUNTRIES.GH ||
        this.userCountry === COUNTRIES.KE
      ) {
        list.splice(1, 1)
        list.splice(8, 1)
        // list.slice(3, 1)
      }

      return list
    },
    transactionSalesSorted() {
      if (this.filterBy === 'all') {
        return this.transactionSales
      } else {
        return this.transactionSales.filter(
          (transaction) => transaction.status === this.filterBy
        )
      }
    },
    transactionPurchaseSorted() {
      if (this.filterBy === 'all') {
        return this.transactionPurchase
      } else {
        return this.transactionPurchase.filter(
          (transaction) => transaction.status === this.filterBy
        )
      }
    },
    transactionGiftcardSorted() {
      if (this.filterBy === 'all') {
        return this.transactionGiftcard
      } else {
        return this.transactionGiftcard.filter(
          (transaction) => transaction.status === this.filterBy
        )
      }
    }
  },
  methods: {
    async getPromoCodeUsage() {
      const res = await promoCodes.promoCodes().getCodeUsage({
        params: {
          limit: 0,
          populate: ['product', 'promoCode', 'user', 'transaction']
        },
        requester: 'user'
      })
      // this.promoCodeHistory = res.data.data.data
      this.promoCodeHistory = res.data.data.data.map((history) => ({
        ...history,
        trxAmount: history.originalAmount,
        promoCodeAmount: history.discountValue,
        trxDiscount: history.discountValue
      }))
    },
    async getAllTransaction() {
      this.loading = true

      const res = await transactionApi
        .transactions()
        .getAll(['currency', 'bank', 'giftcard'])

      // Purchase
      this.transactionPurchase = res.data.data.data
        .filter((transaction) => transaction.type === 'purchase')
        .map((transaction) => ({
          ...transaction,
          id: transaction._id,
          transactionID: transaction._id,
          date: transaction.date_string,
          currency: transaction.currency
            ? transaction.currency !== null
              ? transaction.currency.name
              : ''
            : '',
          trx: transaction.trx,
          amount: {
            price: numberWithCommas(transaction.amount),
            currency: 'USD'
          },
          rate: {
            price: transaction.rate / transaction.conversionRate,
            currency: this.currency
          },
          price: numberWithCommas(
            transaction.main_amo / transaction.conversionRate
          ),
          status: transaction.status
        }))

      // Sales
      this.transactionSales = res.data.data.data
        .filter(
          (transaction) =>
            transaction.type === 'sale' && transaction.category !== 'giftcard'
        )
        .map((transaction) => ({
          ...transaction,
          id: transaction._id,
          transactionID: transaction._id,
          date: transaction.date_string,
          amount: {
            price: numberWithCommas(transaction.amount),
            currency: 'USD'
          },
          currency: transaction.currency
            ? transaction.currency !== null
              ? transaction.currency.symbol
              : ''
            : transaction.currencyCode,
          trx: transaction.trx,
          rate: {
            price: transaction.rate / transaction.conversionRate,
            currency: this.currency
          },
          price: numberWithCommas(
            transaction.main_amo / transaction.conversionRate
          ),
          status: transaction.status
        }))

      this.transactionGiftcard = res.data.data.data
        .filter((t) => t.category === 'giftcard')
        .filter((t) => Array.isArray(t.images) && t.images.length > 0)
        .map((trx) => ({
          ...trx,
          date: new Date(
            trx.created_at ? trx.created_at : trx.createdAt
          ).toDateString(),
          id: trx._id,
          image: trx.giftcard ? trx.giftcard.image : '',
          amount: numberWithCommas(trx.main_amo / trx.conversionRate)
        }))
      // console.log(this.transactionGiftcard)

      this.loading = false
    },
    async getFundTransfer() {
      const res = await transactionApi.transactions().getFundTransfer()
      this.fundTransfers = res.data.data.data
    },
    async getFundingHistory() {
      const res = await walletApi.data().getFundingRequest()
      this.fundingRequest = res.data.data.data
      console.log(res.data.data.data)
    },
    async getTokenHistory() {
      const res = await walletApi
        .data()
        .walletHistory({ category: 'token', limit: 0 })
      this.tokenHistory = res.data.data.data
    },
    async getBonusHistory() {
      const res = await walletApi
        .data()
        .walletHistory({ category: 'bonus', limit: 0 })
      this.bonusHistory = res.data.data.data
    },
    async getCashbackHistory() {
      const res = await cashbackApi
        .cashbackApi()
        .getCashbackWalletHistory({ limit: 0 })
      this.cashbackHistory = res.data.data.data
    },
    async getHistoryTransaction() {
      const res = await bill.billApi().history.getTransaction({
        status: 'completed'
      })
      this.historyTransaction = res.data.data.data.map((history) => ({
        ...history,
        type: this.formatType(history.type),
        date: new Date(history.createdAt).toDateString(),
        amount: numberWithCommas(history.amount)
      }))
    },
    formatType(type) {
      let t = type.replace(/-/g, ' ')
      t = t.charAt(0).toUpperCase() + t.slice(1, t.length)
      return t
    }
  }
}
</script>

<style>
.custom-tab .v-slide-group__wrapper {
  display: none !important;
}
</style>
